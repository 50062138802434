import React from "react";
import PropTypes from "prop-types";
import kebabCase from "lodash/kebabCase";
import { graphql, Link } from "gatsby";
import Layout from "../components/Layout";
import Content, { HTMLContent } from "../components/Content";
import Seo from "../components/seo";
import Share from "../components/Share/Share";
import PageHeader from "../components/PageHeader";

const BlogPostTemplate = ({
  content,
  contentComponent,
  description,
  tags,
  title,
  helmet,
  image,
}) => {
  const PostContent = contentComponent || Content;

  return (
    <div className="blog-post">
      {helmet || ""}
      <PageHeader title={title} styleName="blog-header" />
      <div className="content-wrapper">
        <HTMLContent content={description} className="blog-description" />

        <div className="blog-body">
          <PostContent content={content} />
        </div>
      </div>
      <div className="blog-footer">
        <Share
          shareText="Share this post via "
          title={title}
          description={description}
          media={image}
          twitter
          linkedin
          pinterest
          facebook
        />
        {tags && tags.length ? (
          <div style={{ marginTop: `4rem` }}>
            <h4>Tags</h4>
            <ul className="taglist">
              {tags.map((tag) => (
                <li key={tag + `tag`}>
                  <Link className="light-link" to={`/tags/${kebabCase(tag)}/`}>
                    {tag}
                  </Link>
                </li>
              ))}
            </ul>
          </div>
        ) : null}
      </div>
    </div>
  );
};

BlogPostTemplate.propTypes = {
  content: PropTypes.node.isRequired,
  contentComponent: PropTypes.func,
  description: PropTypes.string,
  title: PropTypes.string,
  helmet: PropTypes.object,
  image: PropTypes.string,
};

const BlogPost = ({ data }) => {
  const { markdownRemark: post } = data;

  return (
    <Layout>
      <BlogPostTemplate
        content={post.html}
        contentComponent={HTMLContent}
        description={post.frontmatter.description}
        helmet={
          <Seo
            title={`${post.frontmatter.title} | Blog`}
            description={post.frontmatter.description}
            image={post.frontmatter.featuredimage.childImageSharp.fluid.src}
          />
        }
        tags={post.frontmatter.tags}
        title={post.frontmatter.title}
        image={post.frontmatter.featuredimage.childImageSharp.fluid.src}
      />
    </Layout>
  );
};

BlogPost.propTypes = {
  data: PropTypes.shape({
    markdownRemark: PropTypes.object,
  }),
};

export default BlogPost;

export const pageQuery = graphql`
  query BlogPostByID($id: String!) {
    markdownRemark(id: { eq: $id }) {
      id
      html
      frontmatter {
        date(formatString: "MMMM DD, YYYY")
        title
        description
        tags
        featuredimage {
          childImageSharp {
            fluid(maxWidth: 120, quality: 100) {
              ...GatsbyImageSharpFluid
              ...GatsbyImageSharpFluidLimitPresentationSize
            }
          }
        }
      }
    }
  }
`;
