import React from "react";
import PropTypes from "prop-types";
import { FaClipboard } from "@react-icons/all-files/fa/FaClipboard";
import { FaTwitter } from "@react-icons/all-files/fa/FaTwitter";
import { FaFacebook } from "@react-icons/all-files/fa/FaFacebook";
import { FaLinkedin } from "@react-icons/all-files/fa/FaLinkedin";
import { FaPinterest } from "@react-icons/all-files/fa/FaPinterest";
import {
  FacebookShareButton,
  LinkedinShareButton,
  TwitterShareButton,
  PinterestShareButton,
} from "react-share";

import "./Share.scss";

const Share = ({
  shareText,
  title,
  description,
  media,
  url = "https://www.kristielengel.com",
  facebook = true,
  linkedin = false,
  twitter = false,
  pinterest = false,
  clipboard = false,
}) => (
  <div className="post-social">
    <div>{shareText && <span>{shareText}</span>}</div>
    {!!clipboard && (
      <div>
        <FaClipboard />
        copy to clipboard
      </div>
    )}
    {!!facebook && (
      <div>
        <FacebookShareButton
          url={url}
          className="button is-outlined is-rounded facebook"
          quote={title}
          hashtag="#kristielengel"
        >
          <span className="icon">
            <FaFacebook />
          </span>
          <span className="text">Facebook</span>
        </FacebookShareButton>
      </div>
    )}
    {!!twitter && (
      <TwitterShareButton
        url={url}
        className="button is-outlined is-rounded twitter"
        title={title}
        hashtags={["kristielengel", "balance"]}
      >
        <span className="icon">
          <FaTwitter />
        </span>
        <span className="text">Twitter</span>
      </TwitterShareButton>
    )}
    {!!pinterest && (
      <PinterestShareButton
        url={url}
        className="button is-outlined is-rounded pinterest"
        media={`https://www.kristielengel.com${media}`}
        description={description}
      >
        <span className="icon">
          <FaPinterest />
        </span>
        <span className="text">Pinterest</span>
      </PinterestShareButton>
    )}
    {!!linkedin && (
      <LinkedinShareButton
        url={url}
        className="button is-outlined is-rounded linkedin"
        title={title}
        summary={description}
      >
        <span className="icon">
          <FaLinkedin />
        </span>
        <span className="text">LinkedIn</span>
      </LinkedinShareButton>
    )}
  </div>
);

Share.propTypes = {
  socialConfig: PropTypes.shape({
    twitterHandle: PropTypes.string.isRequired,
    config: PropTypes.shape({
      url: PropTypes.string.isRequired,
      title: PropTypes.string.isRequired,
    }),
  }).isRequired,
  tags: PropTypes.arrayOf(PropTypes.string),
};
Share.defaultProps = {
  tags: [],
};

export default Share;
